import onPageReady from './utils/onPageReady';
import polyfill from './polyfills/polyfill-loader';

// import shareWidget from './modules/share.widget.js';
import navigation from './modules/navigation';
import openExternalLinksInNewTab from './utils/openExternalLinksInNewTab';

function initialise() {
    navigation();
    openExternalLinksInNewTab();

    if (document.querySelectorAll('[data-reoako-id]')) {
        import('./modules/reoako.js')
            .then(({ default: reoakoInit }) => {
                reoakoInit();
            });
    }

    if (navigator.userAgent.includes('MSIE') || navigator.appVersion.includes('Trident/')) {
        import(/* webpackChunkName: "ie-11" */ './modules/ie11.js')
            .then(({ default: ie11Init }) => {
                ie11Init();
            });
    }

    /*  Mobile Menu  ------------------------ */
    const mobileNavEl = document.querySelector('.mobile-nav');
    if (mobileNavEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/mobile.nav.js')
            .then(({ default: MobileNav }) => {
                new MobileNav(mobileNavEl);
            });
    }

    // /*  Search Overlay  ------------------------ */
    const searchOverlayTriggerEl = document.querySelectorAll('[data-search-overlay-trigger]');
    if (searchOverlayTriggerEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/search-overlay.js')
            .then(({ default: searchConfig }) => {
                searchConfig(searchOverlayTriggerEl);
            });
    }

    if (__SENTRY_DSN) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(__SENTRY_DSN);
            })
            .then(() => {
                // Capturing a test error to check Sentry FE is working
                if (!__DEVELOPMENT) {
                    try {
                        throw new Error('This is a test error after Sentry FE initialization!');
                    } catch (error) {
                        window.__sentry.captureException(error);
                    }
                }
            });
    }

    /*  Tables  ------------------------ */
    const tables = document.querySelectorAll('table');
    if (tables.length > 0) {
        import(/* webpackChunkName: "tables" */ './modules/responsive-tables.js')
            .then(({ default: tablesInit }) => {
                tablesInit([...tables]);
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    if (document.querySelector('[data-video]')) {
        import(/* webpackChunkName: `video` */ './modules/video.js')
            .then(({ default: video }) => {
                video.init();
            }).catch(err => {
                console.log(err);
            });
    }

    /*  Google maps  -------------------------- */
    const googleMapsEls = document.querySelectorAll('[data-google-map]');
    if (googleMapsEls.length > 0) {
        import(/* webpackChunkName: "googlemaps" */ './modules/google-maps.js')
            .then(({ default: googleMapsInit }) => {
                googleMapsInit([...googleMapsEls]);
            });
    }

    /*  Calendar  -------------------------- */
    const calendarEls = document.querySelectorAll('[data-calendar]');
    if (calendarEls.length > 0) {
        import(/* webpackChunkName: "calendar" */ './modules/calendar.js')
            .then(({ default: calendarInit }) => {
                calendarInit([...calendarEls]);
            });
    }

    /*  Horizontal scroll  -------------------------- */
    const horizontalScrollEls = document.querySelectorAll('.horizontal-scroll');
    if (horizontalScrollEls.length > 0) {
        import(/* webpackChunkName: "horizontal-scroll" */ './modules/horizontal-scroll.js')
            .then(({ default: horizontalScrollInit }) => {
                horizontalScrollInit([...horizontalScrollEls]);
            });
    }

    /*  News page  -------------------------- */
    const newsContainerEl = document.getElementById('news-container');
    if (newsContainerEl) {
        import(/* webpackChunkName: "news" */ './modules/news.js')
            .then(({ default: newsInit }) => {
                newsInit(newsContainerEl);
            });
    }

    /*  Event form  -------------------------- */
    const eventFormEls = document.querySelectorAll('[data-event-form]');
    if (eventFormEls) {
        import(/* webpackChunkName: "event-form" */ './modules/event-form.js')
            .then(({ default: eventFormInit }) => {
                eventFormInit(eventFormEls);
            });
    }

    /*  Filter select  -------------------------- */
    const filterSelectEls = document.querySelectorAll('.interest-picker');
    if (filterSelectEls.length > 0) {
        import(/* webpackChunkName: "interest-picker" */ './modules/interest-picker.js')
            .then(({ default: filterSelectInit }) => {
                filterSelectInit([...filterSelectEls]);
            });
    }

    /*  Payment form  -------------------------- */
    const paymentFormEl = document.getElementById('online-payment-form');
    if (paymentFormEl) {
        import(/* webpackChunkName: "online-payment" */ './modules/online-payment-form.js')
            .then(({ default: paymentFormInit }) => {
                paymentFormInit(paymentFormEl);
            });
    }
}

polyfill().then(() => onPageReady(initialise));
